export default [
  {
    name: "Dashboard",
    route: "/dashboard-admin",
    icon: "bi bi-grid-fill",
    access: ["pastor", "p12"],
  },

  {
    name: "My Network",
    route: "/users",
    icon: "bi bi-people-fill",
    access: ["pastor", "p12"],
  },
  // {
  //   name: "Attendance",
  //   route: "/attendance",
  //   icon: "bi bi-person-fill",
  //   access: ["pastor", "p12"],
  // },
  {
    name: "All Members",
    icon: "bi bi-person-fill",
    access: ["pastor"],
    route: [
      {
        name: "Pending Members",
        route: `/collection/members?where={"status":"Pending"}`,
        icon: "bi bi-diagram-2-fill",
        access: ["pastor"],
      },
      {
        name: "Verified Members",
        route: `/collection/members?where={}`,
        icon: "bi bi-diagram-3-fill",
        access: ["pastor", "member"],
      },
    ],
  },
  {
    name: "Events",
    route: "/events",
    icon: "bi bi-calendar-event-fill",
    access: ["pastor", "member", "p12"],
    route: [
      {
        name: "Create Events",
        route: "/events-lists",
        icon: "bi bi-diagram-2-fill",
        access: ["pastor"],
      },
      {
        name: "View Events",
        route: "/events",
        icon: "bi bi-diagram-3-fill",
        access: ["pastor", "member", "p12"],
      },
    ],
  },
  {
    name: "Organizations",
    icon: "bi bi-diagram-3-fill",
    route: [
      {
        name: "P12 Members",
        route: `/collection/members?where={"is_p12":true}`,
        icon: "bi bi-diagram-2-fill",
      },
      {
        name: "144 Members",
        route: `/collection/members?where={"is_144":true}`,
        icon: "bi bi-diagram-3-fill",
      },
      {
        name: "1728 Members",
        route: `/collection/members?where={"is_1728":true}`,
        icon: "bi bi-diagram-3-fill",
      },
      {
        name: "Youth Members",
        route: `/collection/members?where={"is_youth":true}`,
        icon: "bi bi-mortarboard-fill",
      },
      {
        name: "Young Pro Members",
        route: `/collection/members?where={"is_young":true}`,
        icon: "bi bi-buildings-fill",
      },
      {
        name: "Entrepreneur Members",
        route: `/collection/members?where={"is_entre":true}`,
        icon: "bi bi-briefcase-fill",
      },
    ],
    access: ["pastor", "p12"],
  },
  {
    name: "Ministry",
    icon: "bi bi-diagram-3-fill",
    route: [
      {
        name: "Multimedia Team",
        route: `/collection/members?where={"is_multimedia":true}`,
        icon: "bi bi-camera2",
      },
      {
        name: "Usher Team",
        route: `/collection/members?where={"is_usher":true}`,
        icon: "bi bi-people-fill",
      },
      {
        name: "Music Team",
        route: `/collection/members?where={"is_worship":true}`,
        icon: "bi bi-music-note-beamed",
      },
      {
        name: "Security Team",
        route: `/collection/members?where={"is_security":true}`,
        icon: "bi bi-person-fill-lock",
      },
      {
        name: "IT Team",
        route: `/collection/members?where={"is_it":true}`,
        icon: "bi bi-braces",
      },
      {
        name: "Dancer Team",
        route: `/collection/members?where={"is_dance":true}`,
        icon: "bi bi-speaker-fill",
      },
    ],
    access: ["pastor", "p12"],
  },

  {
    name: "Settings",
    icon: "bi bi-gear-fill",
    route: [
      {
        name: "Edit Account",
        route: "/account",
        icon: "bi bi-person-fill-gear",
      },
    ],
    access: ["pastor", "p12", "member"],
  },
];
