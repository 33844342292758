import React from "react";
import NavBar from "../../components/navbar";
import BaseListPage from "../../base/BaseListPage";
import withRouter from "../../withRouter";
import NotificationPresenter from "./NotificationPresenter";
import { useNotification } from "../../NotificationContext";
import { format, formatDistanceToNow } from "date-fns";
import { countObjectUseCase, findObjectUseCase } from "../../usecases/object";

class NotificationPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new NotificationPresenter(
      this,
      findObjectUseCase,
      countObjectUseCase
    );
    this.state = {
      objects: [],
      selected: [],
      loading: true,
      total: 0,
      count: 0,
    };
  }

  getCollectionName() {
    return "notifications";
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  setObjects(objects) {
    this.setState({ objects, loading: false }, () => {
      this.updateUnseenCount();
    });
  }

  setTotal(total) {
    this.setState({ total });
  }

  updateUnseenCount() {
    const unseenCount = this.state.objects.filter(
      (item) => !item.isView
    ).length;
    this.props.setUnseenCount(unseenCount); // Update the global context
  }

  formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  render() {
    const { objects } = this.state;

    console.log("on", objects);

    return (
      <>
        <NavBar />
        <div>
          <h1 className="notif ms-4 mt-4 fw-bold">Notifications</h1>
        </div>
        <div className="p-3 p-lg-4">
          {objects.map((item, index) => (
            <div
              key={index}
              onClick={() => this.presenter.onClickView(item, index)}
              className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4"
            >
              <div>
                <p className="fw-bold" style={{ color: "#004E9B" }}>
                  {item.isView === false ? (
                    <i
                      className="bi bi-circle-fill text-danger me-2"
                      style={{ fontSize: "10px" }}
                    ></i>
                  ) : null}
                  {item.name}
                </p>
                <p className="fw-light" style={{ color: "#32312F" }}>
                  {item.description}
                </p>
              </div>
              <div className="row">
                <div className="col-6">
                  <p style={{ color: "#004E9B", opacity: "0.5" }}>
                    {/* {format(new Date(item?.eventDate), "MM/dd/yyyy")} */}
                    {this.formatDate(item?.eventDate)}
                  </p>
                </div>
                <div className="col-6 text-end">
                  <p style={{ color: "#004E9B", opacity: "0.5" }}>
                    {formatDistanceToNow(new Date(item?.createdAt), {
                      addSuffix: true,
                    })}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
}

export default withRouter((props) => {
  const { setUnseenCount } = useNotification();
  return <NotificationPage {...props} setUnseenCount={setUnseenCount} />;
});
