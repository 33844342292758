import React, { Component } from "react";
import BaseFormPresenter from "../../../base/BaseFormPresenter";
import { dialog } from "nq-component";
import { findObjectUseCase } from "../../../usecases/object";

class EventDialogPresenter extends BaseFormPresenter {
  async save(object, user) {
    console.log("user save", user);
    console.log("object save", object);
    const query = {
      where: {
        "user.id": user.id,
      },
    };
    const users = await findObjectUseCase().execute("members", query);
    console.log("usersssss", users);
    const userForEvents = users[0];
    if (this.object.id) {
      this.change.id = this.object.id;
    } else {
      // this.change.acl = this.view.getAcl();
      this.change.event = object;
      this.change.participant = userForEvents;
      this.change.eventId = object.id;
      console.log("obss", object);
      userForEvents.event = userForEvents.event || [];
      userForEvents.event.push({ id: object.id });
    }
    console.log("regis", this.change);

    console.log("userForEvents", userForEvents);

    try {
      await this.upsertUseCase.execute("members", userForEvents);
      await this.upsertUseCase.execute("registrations", this.change);
      this.change = {};
    } catch (error) {
      throw error; // rethrow the error to be caught by the caller
    }
  }

  async submit(object, user) {
    // alert("hi");
    // if (Object.values(this.change).length === 0) {
    //   this.view.showSuccessSnackbar("Successfully saved!");
    //   return;
    // }
    try {
      this.view.submitting();
      await this.save(object, user);
      this.view.submissionSuccess();
      dialog.close();
      this.view.navigateTo("/events");
      this.view.showSuccessSnackbar("Successfully saved!");
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }
}

export default EventDialogPresenter;
